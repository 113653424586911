import { Button, Center } from '@chakra-ui/react'
import { a, easings, useSpring } from '@react-spring/three'
import { Html, OrbitControls, useGLTF } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import {
  Suspense, useEffect, useMemo, useRef, useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Color,
  DirectionalLight,
  Mesh,
  MeshPhongMaterial,
  Object3D,
  OrthographicCamera,
  PointLight,
} from 'three'
import { useCameraGoTo } from '../../../../../hooks/camera/useCameraGoTo'
import { useMounted } from '../../../../../hooks/useMounted'

const testMat = new MeshPhongMaterial({
  color: 0x222222,
  shininess: 0.05,
  emissiveIntensity: 0.05,
})
const SoundRoom = () => {
  const [isActive, setIsActive] = useState(false)
  const { domElement } = useThree((s) => s.gl)
  const spotlightTarget = useMemo(() => {
    const obj = new Object3D()
    obj.position.set(-2.5, 0, 0)
    return obj
  }, [])
  const cam = useThree((s) => s.camera)
  const controls = useThree((s) => s.controls) as any | undefined
  const [neonColor] = useState(new Color(0xffffff))
  const [neonMat] = useState(
    new MeshPhongMaterial({ color: neonColor, emissiveIntensity: 2.5 }),
  )
  const [upperLight] = useState(new DirectionalLight(0xffffff, 1))
  const [lowerLight] = useState(new PointLight(neonColor, 2))
  const { nodes } = useGLTF('/assets/3d/rooms/SOUND.glb') as unknown as {
    nodes: Record<string, Object3D>
  }
  const meshes = useMemo(
    () => Object.entries(nodes).reduce((acc, [id, obj]) => {
      if ((obj as Mesh).isMesh) return { ...acc, [id]: obj as Mesh }
      return acc
    }, {} as Record<string, Mesh>),
    [nodes],
  )

  const {
    nodes: { PLAY },
  } = useGLTF('/assets/3d/theater/play.glb') as unknown as {
    nodes: Record<string, Mesh>
  }

  const withListeners = useMemo(
    () => Object.entries(meshes).reduce((acc, [id, mesh]) => {
      const m = mesh
      const isClickable = m.name === 'dj5000'
      if (mesh.name === 'Cylinder') {
        m.material = testMat
        m.material.needsUpdate = true
        m.receiveShadow = true
      } else if (mesh.name.startsWith('NEON_')) {
        m.material = neonMat
        m.receiveShadow = true
        m.castShadow = false
      } else {
        m.castShadow = true
      }
      return [
        ...acc,
        {
          mesh,
          onClick: isClickable
            ? () => {
              setIsActive(!isActive)
            }
            : undefined,
          onPointerEnter: isClickable
            ? () => {
              domElement.style.cursor = 'pointer'
            }
            : undefined,
          onPointerLeave: isClickable
            ? () => {
              domElement.style.cursor = 'default'
            }
            : undefined,
        },
      ]
    }, [] as Array<{ mesh: Mesh; onClick?: () => void; onPointerEnter?: () => void; onPointerLeave?: () => void }>),
    [meshes, neonMat, isActive, domElement],
  )
  const goTo = useCameraGoTo()
  const navigate = useNavigate()
  const ocRef = useRef<any>(null)
  useEffect(() => {
    // cam.position.set(0, 3, 5.5)
    cam.position.set(0.01, 20, 0)
    cam.lookAt(0, 3, 0)
    if (ocRef.current) ocRef.current.enabled = false
    goTo({
      position: [5, 7, 0],
      lookAt: [0, 4, 0],
      delay: 1000,
      config: { duration: 1800, easing: easings.easeInOutCubic },
    }).then(() => {
      if (ocRef.current) ocRef.current.enabled = true
    })
    const shadowCam = upperLight.shadow.camera as any as OrthographicCamera
    shadowCam.top = 200
    shadowCam.bottom = -200
    shadowCam.right = 200
    shadowCam.left = -200
    upperLight.shadow.mapSize.set(2048, 2048)
    upperLight.castShadow = true
    upperLight.position.set(0, 30, 0)
    upperLight.target.position.set(0, 0, 0)
  }, [cam, upperLight, goTo, controls])

  const { playScale } = useSpring({ playScale: isActive ? 0 : 1 })
  const mounted = useMounted(500)
  useFrame(({ clock }) => {
    const h = (Math.cos(clock.elapsedTime / 10) + 1) * 0.5 * 0.8 - 0.2
    neonColor.setHSL(h, 0.9, 0.5)
    neonMat.color.copy(neonColor)
    // upperLight.color.copy(neonColor)
    lowerLight.color.copy(neonColor)
    neonMat.emissive.copy(neonColor)
    neonMat.emissive.r *= 3
    // neonMat.emissive.multiplyScalar(1.5)
  })
  useEffect(() => {
    if (isActive) {
      if (controls?.enabled) controls.enabled = false
      goTo({
        position: [4, 10, 0],
        lookAt: [0, 6.7, 0],
        config: { duration: 1800, easing: easings.easeInOutCubic },
      }).then(() => {
        if (controls?.enabled) controls.enabled = false
      })
    } else {
      goTo({
        position: [5, 7, 0],
        lookAt: [0, 5, 0],
        config: { duration: 1800, easing: easings.easeInOutCubic },
      }).then(() => {
        if (controls) controls.enabled = true
      })
    }
  }, [isActive, controls, goTo])
  return (
    <>
      <group position={[0, 1.5, 0]}>
        {/* <ambientLight intensity={0.4} /> */}
        <primitive object={upperLight} />
        <primitive object={lowerLight} position={[0, 0.5, 0]} />
        {withListeners.map(({ mesh, ...listeners }) => (
          <mesh
            key={mesh.name}
            geometry={mesh.geometry}
            material={mesh.material}
            position={mesh.position}
            castShadow={mesh.castShadow}
            receiveShadow={mesh.receiveShadow}
            scale={mesh.scale}
            rotation={mesh.rotation}
            {...listeners}
          />
        ))}
      </group>
      <Html
        position={[0, 1.5, 0]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        transform
        center
        style={{
          opacity: mounted && !isActive ? 1 : 0,
          transition: `opacity 500ms ease ${!isActive ? 800 : 0}ms`,
          pointerEvents: !isActive ? 'auto' : 'none',
        }}
      >
        <Button onClick={() => navigate('/')}>{'< HOME'}</Button>
      </Html>
      {/* @ts-ignore */}
      <a.mesh
        scale={playScale.to((s) => [s * 0.3, s * 0.3, s * 0.3])}
        // @ts-ignore
        rotation={playScale.to((s) => [0, -Math.PI * 1.5 * s, 0])}
        position={[-2.5, 4, 0]}
        onClick={() => setIsActive(!isActive)}
        onPointerEnter={() => {
          domElement.style.cursor = 'pointer'
        }}
        onPointerLeave={() => {
          domElement.style.cursor = 'default'
        }}
        geometry={PLAY.geometry}
        material={PLAY.material}
      />

      <Html
        scale={[0.2, 0.2, 0.2]}
        position={[0, 6.6, 0]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        pointerEvents={isActive ? 'auto' : 'none'}
        transform
        style={{
          opacity: mounted && isActive ? 1 : 0,
          transition: `opacity 500ms ease ${mounted && isActive ? 500 : 0}ms`,
          pointerEvents: mounted && isActive ? 'auto' : 'none',
        }}
        center
      >
        <iframe
          style={{ borderRadius: '12px' }}
          src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1969277744&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'
          width='600'
          height='600'
          allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
          loading='lazy'
        ></iframe>
        <Center w='100%'>
          <Button
            position='absolute'
            textShadow='0 -1px 4px #00000088'
            fontSize='64px'
            bottom='-100px'
            onClick={() => setIsActive(false)}
          >
            {'EXIT'}
          </Button>
        </Center>
      </Html>
      <spotLight
        castShadow
        angle={Math.PI / 5}
        position={[-2.5, 5, 0]}
        target={spotlightTarget}
      />
      <OrbitControls
        ref={ocRef}
        makeDefault
        autoRotate
        autoRotateSpeed={isActive ? 0 : 0.5}
        minPolarAngle={Math.PI / 5}
        enablePan={false}
        // enableZoom={false}
        maxPolarAngle={Math.PI / 2.5}
        rotateSpeed={1}
        target={[0, 5, 0]}
        // maxDistance={5}
      />
    </>
  )
}

const _Sound = () => (
  <>
    <SoundRoom />
  </>
)

export const Sound = () => (
  <Suspense>
    <_Sound />
  </Suspense>
)

useGLTF.preload('/assets/')
